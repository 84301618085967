@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
  width: 400px;
  margin: 0 auto;
  height: 100vh;
  display: flex;

}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 10%;
  padding-top: 10%;
}

.form>h1 {
  align-self: flex-start;
  color: rgb(0, 0, 32);
  padding-bottom: 3%;
  padding-top: 5%;

}

.form>h2 {
  padding-bottom: 3%;
  padding-top: 5%;
  color: rgb(0, 0, 32);

  font-size: xxx-large;
}

.form>h3 {
  padding-top: 10%;
  align-self: flex-start;
  color: rgb(0, 0, 0)
}

.form>input,
textarea {
  width: 75%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  align-self: center;
}

.form>input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.form>textarea {
  height: 150px;
  max-width: 100vh;
  min-height: 100px;
  align-self: center;
}

.form>label {
  padding-bottom: 10px;
  padding-left: 12.5%;
  color: rgba(0, 0, 0, 0.849);
  font-weight: bold;
  align-self: flex-start;
}

textarea {
  resize: none;
}

.form>button {
  width: 35%;
  align-self: center;
  padding: 10px;
  border: none;
  background-color: rgb(2, 2, 110);
  font-weight: 500;
  font-size: 20px;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form>button:hover {
  background-color: rgb(0, 0, 196);
}

.glow-on-hover {

  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  background: linear-gradient(45deg, #e8e0e0, #dbf3f5, #eaeade, #e4e9e2, #788886, #acaebc, #a9a1b2, #ae9caa, #e9ecec87);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}