/* .bio {
    text-align: center;
    color: linear-gradient(-45deg, #96ee52, #423ce7, #cfd523, #ab23d5);
    min-width: 100%;
    font-size: 42px;
} */
.bioHello {
  text-align: center;
  color: rgb(255, 255, 255);
  min-width: 100%;
  font-size: 10px;

}


* {
  box-sizing: border-box;
}

/* body {
    display: grid;
    place-items: center;
    min-height: 100vh;
    /* background: hsl(0 0% 20%); 
  } */

.boujee-text {
  text-align: center;
  --bg-size: 400%;
  --color-one: rgb(7, 18, 73);
  --color-two: rgb(255, 255, 255);
  font-family: sans-serif;
  font-size: clamp(3rem, 25vmin, 8rem);
  background: lightblue
    /*  linear-gradient(
        90deg,
        var(--color-one),
        var(--color-two),
        var(--color-one) 
      )*/
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  mix-blend-mode: color-dodge
}


.boujee-text-2 {
  text-align: center;
  --bg-size: 400%;
  --color-one: rgb(7, 18, 73);
  --color-two: rgba(255, 255, 255, 0.366);
  font-family: sans-serif;
  font-size: clamp(2rem, 10vmin, 3rem);
  background: var(--color-two);
  color: transparent;
  background-clip: text;
}

.boujee-text-3 {
  padding: 30px;
  text-align: center;
  --bg-size: 400%;
  --color-one: rgb(7, 18, 73);
  --color-two: rgb(255, 255, 255);
  font-family: sans-serif;
  font-size: clamp(1rem, 5vmin, 1rem);
  background: linear-gradient(90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}


@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 65s linear infinite;
  }

  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .boujee-text {
    text-align: center;
    --bg-size: 400%;
    --color-one: rgb(7, 18, 73);
    --color-two: rgb(255, 255, 255);
    font-family: sans-serif;
    font-size: clamp(2rem, 10vmin, 3rem);
    background: lightblue
      /*  linear-gradient(
        90deg,
        var(--color-one),
        var(--color-two),
        var(--color-one) 
      )*/
      0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    mix-blend-mode: color-dodge
  }


}