* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 45s ease infinite;
    min-height: 100vh;
    /* width: max-content;
    display: flex;
    justify-content: center;
    align-items: center; */
    /* place-items: center; */
    
}

anchor{
    scroll-margin-top: 200px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.card {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border-radius: 10px;
    width: 90%;
    min-height: "100%";
    z-index: 1;
  }


@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');




