img {
    border-radius: 50%;
    padding: 3%;
  }
  .center {
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  
  p{
    font-size: large;
  }

  a{
    z-index: 1001;
  }