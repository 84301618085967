.square {
    position: relative;
    margin: 10% 10px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square span:nth-child(1) {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 85%;
    height: 85%;
    border: 2px solid black;
    border-radius: 32% 58% 69% 43% / 48% 32% 59% 55%;
    transition: 0.5s;
    animation: animate 6s linear infinite;
}

.linkedin:hover span:nth-child(1) {
    border: none;
    background: #f07e6e;
}

.twitter:hover span:nth-child(1) {
    border: none;
    background: #84cdfa;
}

.github:hover span:nth-child(1) {
    border: none;
    background: #5ad1cd;
}

.square span:nth-child(2) {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 85%;
    height: 85%;
    border: 2px solid black;
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    transition: 0.5s;
    animation: animate 4s linear infinite;
}

.linkedin:hover span:nth-child(2) {
    border: none;
    background: #f07e6e;
}

.twitter:hover span:nth-child(2) {
    border: none;
    background: #84cdfa;
}

.github:hover span:nth-child(2) {
    border: none;
    background: #5ad1cd;
}

.square span:nth-child(3) {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 85%;
    height: 85%;
    border: 2px solid black;
    border-radius: 31% 45% 74% 35% / 38% 56% 51% 87%;
    transition: 0.5s;
    animation: animate2 10s linear infinite;
}

.linkedin:hover span:nth-child(3) {
    border: none;
    background: #f07e6e;
}

.twitter:hover span:nth-child(3) {
    border: none;
    background: #84cdfa;
}

.github:hover span:nth-child(3) {
    border: none;
    background: #5ad1cd;
}

.content {
    position: relative;
    padding: 40px 60px;
    color: #fff;
    text-align: center;
    transition: 0.5s;
    z-index: 4;
}

.content a {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    border: 2px solid #fff;
    padding: 6px 18px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    border-radius: 73% 27% 44% 56% / 49% 44% 56% 51%;
}

.content a:hover {
    background: white;
    color: #333;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate2 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}